import * as React from "react";
import { Script } from "gatsby";
import Lightbox from "yet-another-react-lightbox";
import Zoom from "yet-another-react-lightbox/plugins/zoom"
import "yet-another-react-lightbox/styles.css";

const slides = [
  {
    src: "/static/img/portfolio/wizard-form/registration.png",
  },
  {
    src: "/static/img/portfolio/wizard-form/coordinator.png",
  },
  {
    src: "/static/img/portfolio/wizard-form/verification.png",
  },
  {
    src: "/static/img/portfolio/wizard-form/coordinator-details.png",
  },
  {
    src: "/static/img/portfolio/wizard-form/select-coordinators.png",
  },
  {
    src: "/static/img/portfolio/wizard-form/agreement.png",
  },
  {
    src: "/static/img/portfolio/wizard-form/submitted.png",
  },
];

export default function App() {
  return (
    <>
      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-5LCM3NN"
          height="0"
          width="0"
          style={{display:'none', visibility:'hidden'}}
        ></iframe>
      </noscript>
      <Lightbox open={true} slides={slides} render={{iconClose: () => null}} plugins={[Zoom]} />
    </>
  );
}

export function Head() {
  return (
    <>
      <title>Wizard Form OctoberCMS</title>
      <Script id="analytics" src="/js/gtag.js" />
    </>
  );
}
